import React, { useEffect, useRef, FC } from 'react'

interface HtmlContentProps
    extends React.HTMLAttributes<HTMLElement> {
    html: string
}

const DynamicHTMLContent : FC<HtmlContentProps> = ({html, ...props}) => {

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!divRef.current) throw new Error("html prop cant't be null")

    const slotHtml = document.createRange().createContextualFragment(html)
    divRef.current.innerHTML = '' 
    divRef.current.appendChild(slotHtml)
  }, [html, divRef])

  return (<div {...props} ref={divRef} />)
}

export default DynamicHTMLContent