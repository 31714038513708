import {EQLGame, Frontend} from './eql'


export default class Intralot implements Frontend {
    code: string = "INTRALOT";
    events: string[] = [];
    playMode: string|null = null;
    playerId: string|null = null;
    sessionToken: string|null = null;
    ticketId: string|null = null;
    gameId: string|null = null;
    url: string|null = null;
    platform: string|null = null;
    language: string|null = null;
    currency: string|null = null;
    lobbyUrl: string|null = null;
    depositUrl: string|null = null;
    realityCheckInterval: number = 0;
    realityCheckRemaining: number = 0;

    SendClientEvent = (eventName: string, gameId: string|null, languageCode: string, data?: any) => {
    }


    ApplyLaunchParams = (game: EQLGame):EQLGame => {
        const queryParams = new URLSearchParams(window.location.search);

        if(!game.STATE.playMode) game.STATE.playMode = "D"
        this.playMode = game.STATE.playMode
        
        if(this.playMode) {
            if(this.playMode==="M" || this.playMode==="D") {
                this.gameId = queryParams.get("gameId");
                this.url = queryParams.get("url");
                this.platform = queryParams.get("platform");
                this.language = queryParams.get("language");
                this.currency = queryParams.get("currency");
                this.lobbyUrl = queryParams.get("lobbyUrl");
                
                game.GAME_ID = this.gameId;
                game.GAME_URL = this.url;
                game.LOBBY_URL = this.lobbyUrl;
                game.PLATFORM = this.platform;

                if(this.language) {
                    game.STATE.languageCode = this.language;
                }

                if(this.currency) {
                    game.STATE.currencyCode = this.currency;
                }
            } 
            
            if(this.playMode==="M") {
                this.playerId = queryParams.get("playerId");
                game.STATE.playerId = this.playerId;

                this.depositUrl = queryParams.get("depositUrl");
                game.DEPOSIT_URL = this.depositUrl;        
                
                const realityCheckInterval = queryParams.get("realityCheckInterval")
                if(realityCheckInterval) {
                    this.realityCheckInterval = Number.parseInt(realityCheckInterval)
                    game.STATE.realityCheckInterval = this.realityCheckInterval;
                }

                const realityCheckRemaining = queryParams.get("realityCheckRemaining")
                if(realityCheckRemaining) {
                    this.realityCheckRemaining = Number.parseInt(realityCheckRemaining)
                }
            } else if(this.playMode==="H") {
                this.ticketId = queryParams.get("ticketId")
                if(this.ticketId) {
                    game.STATE.ticketId = this.ticketId;
                }
            } 
        }
        return game;
    }
}