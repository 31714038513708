import {EQLGame, Frontend} from './eql'


export default class Pariplay implements Frontend {
    code: string = "PARIPLAY";
    events: string[] = [
        "roundStart",
        "ticketReceived",
        "gameError",
        "roundStarted",
        "gameRoundStarted",
        "balanceUpdated",
        "roundEnded",
        "gameRoundEnded",
        "majorPrize",
        "onAppFrameReady",
        "gameDataLoaded",
        "gameLoaded",
        "gameReady",
        "LogoutSanityCheck",
        "quit",
        "ReturnToLobby",
        "backToLobby",
        "cashier",
        "balance",
        "MovetoMoney",
        "ReloadGame",
        "setVolume",
    ];


    SendClientEvent = (eventName: string, gameId: string|null, languageCode: string, data?: any) => {
        if(this.events.includes(eventName)) {
            let messageObject:any = { 
                sender: gameId, 
                lang: languageCode, 
                type: eventName,
            };

            if (data !== null && data !== undefined && eventName!=='gameError' && eventName!=='majorPrize') { 
                messageObject.data = data; 
            }

            window.parent.postMessage(messageObject, '*');
        }
    }

    ApplyLaunchParams = (game: EQLGame):EQLGame => {
        const queryParams = new URLSearchParams(window.location.search);

        game.STATE.extraData = queryParams.get("extraData"); 
        game.DEPOSIT_URL = queryParams.get("cashierUrl");        
        game.CLAIM_URL = queryParams.get("cashierUrl"); 
        game.LOBBY_URL = queryParams.get("homeUrl") || "(api)";

        const realityCheckIntervalString = queryParams.get("realityCheckTimeout")
        if(realityCheckIntervalString) {
            let realityCheckInterval = Number.parseInt(realityCheckIntervalString)
            game.STATE.realityCheckInterval = realityCheckInterval*60;
        }

        return game;
    }
}