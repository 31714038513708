import {EQLGame, Frontend} from './eql'


export default class Nextlot implements Frontend {
    code: string = "NEXLOT";
    events: string[] = [];
    playMode: string|null = null;
    playerId: string|null = null;
    sessionToken: string|null = null;
    ticketId: string|null = null;
    gameId: string|null = null;
    url: string|null = null;
    platform: string|null = null;
    language: string|null = null;
    currency: string|null = null;
    lobbyUrl: string|null = null;
    depositUrl: string|null = null;
    claimUrl: string|null = null;
    moneyModeUrl: string|null = null;

    SendClientEvent = (eventName: string, gameId: string|null, languageCode: string, data?: any) => {
    }

    ApplyLaunchParams = (game: EQLGame):EQLGame => {
        const queryParams = new URLSearchParams(window.location.search);

        if(!game.STATE.playMode) game.STATE.playMode = "D"
        this.playMode = game.STATE.playMode
        
        if(this.playMode) {
            game.STATE.playMode = this.playMode;

            if(this.playMode==="M" || this.playMode==="D") {
                this.gameId = queryParams.get("gameId");
                this.url = queryParams.get("host");
                this.platform = queryParams.get("platform");
                this.language = queryParams.get("language");
                this.currency = queryParams.get("currency");
                this.lobbyUrl = queryParams.get("lobbyUrl");
                
                game.GAME_ID = this.gameId;
                game.GAME_URL = this.url;
                game.PLATFORM = this.platform;

                if(this.lobbyUrl) {
                    game.LOBBY_URL = this.lobbyUrl;
                }

                if(this.language) {
                    game.STATE.languageCode = this.language;
                }

                if(this.currency) {
                    game.STATE.currencyCode = this.currency;
                }
            } 
            
            this.moneyModeUrl = queryParams.get("moneyModeUrl");
            game.MONEY_MODE_URL = this.moneyModeUrl;    
            
            if(this.playMode==="M") {
                this.playerId = queryParams.get("playerId");
                game.STATE.playerId = this.playerId; 

                this.depositUrl = queryParams.get("depositUrl");
                game.DEPOSIT_URL = this.depositUrl;        

                this.claimUrl = queryParams.get("claimUrl");
                game.CLAIM_URL = this.claimUrl;            
            }
        }
        return game;
    }
}